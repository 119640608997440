import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { globalSlice, GlobalState } from './slices';
import { credentialSlice, CredentialState } from './slices/credential';
import { DemoCredential, demoCredentialSlice, DemoCredentialState } from './slices/democredential';


export interface ApplicationState {
    globalState: GlobalState;
    demoCredentialState: DemoCredentialState;
    credentialState: CredentialState;
}

const rootReducer = combineReducers<ApplicationState>({
    globalState: globalSlice.reducer,
    demoCredentialState: demoCredentialSlice.reducer,
    credentialState: credentialSlice.reducer

});
export const store = configureStore({
    reducer: rootReducer
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

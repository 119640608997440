import { useKeycloak } from '@react-keycloak/web';
import React, { FC, useEffect } from 'react';
import { useAppDispatch } from '../../state/hooks';
import { useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DemoCredential, demoCredentialSelector, getDemoCredentials } from '../../state/slices/democredential';
import { AdministrativeIcon, CurvedLine } from '../atoms';
import { formatDateForDisplay } from '../../state';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const ShowCompany: FC = () => {
    const dispatch = useAppDispatch();
    const { keycloak, initialized } = useKeycloak();
    const demoCredentials = useSelector(demoCredentialSelector);
    let navigate = useNavigate();

    useEffect(() => {
            dispatch(getDemoCredentials({jwtToken: keycloak.token!}));
    }, [keycloak.token]);


    function issueOprichtingsActe() {
        navigate('/issue/wallet?id=oprichtingsakte_jwt_vc_json');
    }

    //
    // function issueBevoegdheidCredential() {
    //     navigate('/issue/personal?id=BevoegdheidUittreksel_jwt_vc_json');
    // }

    function getOprichtingsakte(credentialJson: any) {
        return <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Box sx={{marginRight: '20px'}}><AdministrativeIcon/></Box>
                    <Box sx={{marginRight: '20px'}}>
                        <Typography sx={{fontSize: '24px', fontFamily: 'martel', lineHeight: 1}}>Company data {credentialJson?.statutaireNaamVennootschap}</Typography>
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <hr style={{height: '1px', border: 'none', borderTop: '1px solid #E4E4E4', marginTop: '-20px', marginBottom: '20px'}}/>
                <Grid container spacing={0} sx={{fontFamily: 'inter'}}>
                    <Grid item xs={4}>Statutory name Company (NL)</Grid><Grid item xs={8}>{credentialJson?.statutaireNaamVennootschap}</Grid>
                    <Grid item xs={4}>Company Address (NL)</Grid><Grid item xs={8}>{credentialJson?.adresVennootschap}</Grid>
                    <Grid item xs={4}>Legal Entity Type (NL)</Grid><Grid item xs={8}>{credentialJson?.soortVennootschap}</Grid>
                    <Grid item xs={4}>Date of Establishment Legal Entity (NL)</Grid><Grid item xs={8}>{credentialJson?.datumVanOprichtingVennootschap}</Grid>
                    <Grid item xs={4}>Share Capital (NL)</Grid><Grid item xs={8}>€ {credentialJson?.maatschappelijkKapitaal}</Grid>
                    <Grid item xs={4}>Issued Capital (NL)</Grid><Grid item xs={8}>€ {credentialJson?.geplaatstKapitaal}</Grid>
                    <Grid item xs={4}>Date of issue</Grid><Grid item xs={8}>{formatDateForDisplay(credentialJson?.datumVanUitgifte)}</Grid>
                    <Grid item xs={4}>Terms of Use</Grid><Grid item xs={8}>{credentialJson?.termsOfUse}</Grid>
                    <Grid item xs={4}>Name Notary (NL)</Grid><Grid item xs={8}>{credentialJson?.naamNotaris}</Grid>
                    <Grid item xs={4}>Registration Number Notary (NL)</Grid><Grid item xs={8}>{credentialJson?.registratieNummerNotaris}</Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>

    }

    function getBevoegdheden(bevoegdheid: any) {
        return <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Box sx={{marginRight: '20px'}}><AdministrativeIcon/></Box>
                    <Box sx={{marginRight: '20px'}}>
                        <Typography sx={{fontSize: '24px', fontFamily: 'martel', lineHeight: 1}}>Authority data {bevoegdheid?.statutaireNaamVennootschap}</Typography>
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <hr style={{height: '1px', border: 'none', borderTop: '1px solid #E4E4E4', marginTop: '-20px', marginBottom: '20px'}}/>
                {/*// <OCard title1="Gegevens" title2="bevoegdheid" headerIcon={<AdministrativeIcon/>}>*/}
            <Grid container spacing={0} sx={{fontFamily: 'inter'}}>
                <Grid item xs={4}>Statutory name Company (NL)</Grid><Grid item xs={8}>{bevoegdheid?.statutaireNaamVennootschap}</Grid>
                <Grid item xs={4}>Company Address (NL)</Grid><Grid item xs={8}>{bevoegdheid?.adresVennootschap}</Grid>
                <Grid item xs={4}>Date of Establishment Legal Entity (NL)</Grid><Grid item xs={8}>{bevoegdheid?.datumVanOprichtingVennootschap}</Grid>
                <Grid item xs={4}>Total number of directors (NL)</Grid><Grid item xs={8}>{bevoegdheid?.totaalAantalBestuurdersVennootschap}</Grid>
                <Grid item xs={4}>Type of authority (NL)</Grid><Grid item xs={8}>{bevoegdheid?.typeBevoegdheid}</Grid>
                <Grid item xs={4}>Restriction of authority (NL)</Grid><Grid item xs={8}>{bevoegdheid?.beperkingBevoegdheid}</Grid>
                <Grid item xs={4}>Description Authority (NL)</Grid><Grid item xs={8}>{bevoegdheid?.omschrijvingBevoegdheid}</Grid>

                <Grid item xs={12} sx={{marginTop: '12px'}}><b>Director Data (NL)</b></Grid>
                <Grid item xs={4}>Family name (NL)</Grid><Grid item xs={8}>{bevoegdheid?.bestuurder.geslachtsnaam}</Grid>
                <Grid item xs={4}>Name (NL)</Grid><Grid item xs={8}>{bevoegdheid?.bestuurder.naam}</Grid>
                <Grid item xs={4}>Date of Birth</Grid><Grid item xs={8}>{bevoegdheid?.bestuurder.geboortedatum}</Grid>
                <Grid item xs={4}>Place of birth</Grid><Grid item xs={8}>{bevoegdheid?.bestuurder.geboorteplaats}</Grid>
                <Grid item xs={4}>Title (NL)</Grid><Grid item xs={8}>{bevoegdheid?.bestuurder.titel}</Grid>
                <Grid item xs={4}>Date in office (NL)</Grid><Grid item xs={8}>{bevoegdheid?.bestuurder.datumInFunctie}</Grid>
            </Grid>
            </AccordionDetails>
        </Accordion>

    }

    function getAandeelhouder(bevoegdheid: any) {
        return <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Box sx={{marginRight: '20px'}}><AdministrativeIcon/></Box>
                    <Box sx={{marginRight: '20px'}}>
                        <Typography sx={{fontSize: '24px', fontFamily: 'martel', lineHeight: 1}}>Shareholder data {bevoegdheid?.statutaireNaamVennootschap}</Typography>
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <hr style={{height: '1px', border: 'none', borderTop: '1px solid #E4E4E4', marginTop: '-20px', marginBottom: '20px'}}/>
            <Grid container spacing={0} sx={{fontFamily: 'inter'}}>
                <Grid item xs={4}>Statutory name Company (NL)</Grid><Grid item xs={8}>{bevoegdheid?.statutaireNaamVennootschap}</Grid>
                <Grid item xs={4}>Company Address (NL)</Grid><Grid item xs={8}>{bevoegdheid?.adresVennootschap}</Grid>
                <Grid item xs={4}>Date of Establishment Legal Entity (NL)</Grid><Grid item xs={8}>{bevoegdheid?.datumVanOprichtingVennootschap}</Grid>
                <Grid item xs={4}>Number of shares in possession for this type (NL)</Grid><Grid item xs={8}>{bevoegdheid?.aantalAandelenInBezit}</Grid>
                <Grid item xs={4}>Total number of issued shares for this type (NL)</Grid><Grid item xs={8}>{bevoegdheid?.totaalAantalAandelenUitgegevenDoorVennootschap}</Grid>
                <Grid item xs={4}>Share type (NL)</Grid><Grid item xs={8}>{bevoegdheid?.typeAandeel}</Grid>
                <Grid item xs={12} sx={{marginTop: '12px'}}><b>Aandeelhouder</b></Grid>
                <Grid item xs={4}>Family name (NL)</Grid><Grid item xs={8}>{bevoegdheid?.aandeelhouder.geslachtsnaam}</Grid>
                <Grid item xs={4}>Name (NL)</Grid><Grid item xs={8}>{bevoegdheid?.aandeelhouder.naam}</Grid>
                <Grid item xs={4}>Date of Birth</Grid><Grid item xs={8}>{bevoegdheid?.aandeelhouder.geboortedatum}</Grid>
                <Grid item xs={4}>Place of birth</Grid><Grid item xs={8}>{bevoegdheid?.aandeelhouder.geboorteplaats}</Grid>
            </Grid>
            </AccordionDetails>
        </Accordion>
    }


    function getCard(demoCredential: DemoCredential) {
        let credential = JSON.parse(demoCredential.credentialJson);
        console.log('QQQ credential: ', credential);
        console.log('QQQ credential.bevoegdheden: ', credential.bevoegdheden);
        return <Grid container spacing={2}>
            <Grid item xs={12}>
                {getOprichtingsakte(credential)}
            </Grid>
            {credential.bevoegdheden.map((bevoegdheid: any, index: number) => (
                <Grid item xs={6} key={index}>
                    {getBevoegdheden(bevoegdheid)}
                </Grid>
            ))}
            {credential.aandeelhoudersVennootschap.map((aandeelhouder: any, index: number) => (
                <Grid item xs={6} key={index}>
                    {getAandeelhouder(aandeelhouder)}
                </Grid>
            ))}

        </Grid>
    }

    return (
        <div>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '60px'}}>
                <Box>
                    <Typography sx={{fontSize: '56px', fontFamily: 'unna', lineHeight: 1}}>Deed of Incorporation</Typography>
                    <CurvedLine/>
                </Box>
                <Button variant='contained' onClick={issueOprichtingsActe} sx={{height: '65px', paddingLeft: '40px', paddingRight: '40px'}}>Save in Wallet</Button>
            </Box>
            <Grid container spacing={2} flexDirection={'row'}>
                {demoCredentials.list.map((demoCredential, index) => (
                    <Grid item xs={12} key={index}>
                        {getCard(demoCredential)}
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

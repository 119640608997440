import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';

import { bearerAuth } from '../auth';
import axios from 'axios';


export const issueCredential = createAsyncThunk(
        'credential/issueCredential', ({jwtToken, templateId, callbackUrl}: {jwtToken: string | undefined, templateId: string, callbackUrl: string}, thunkAPI) => {
            thunkAPI.dispatch(setLoading(true));
            const config = {
                headers: {'Authorization': bearerAuth(jwtToken)}
            };

            return axios.post('/manage/vc/' + templateId + "?callback=" + callbackUrl, {}, config)
                .then(response => {
                    console.log('QQQ response: ', response);
                    return response.data
                })
                .finally(() => {
                    thunkAPI.dispatch(setLoading(false));
                });
        },
);

export const startWalletLogin = createAsyncThunk(
    'credential/verifyCredential', ({}: {}, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
        };

        return axios.post('/public/login', {}, config)
            .then(response => {
                console.log('QQQ response: ', response);
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const checkLoginStatus = createAsyncThunk(
    'credential/getCredential', ({sessionId}: {sessionId: string}, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
        };

        return axios.get('/public/login/status/' + sessionId, config)
            .then(response => {
                console.log('QQQ response: ', response);
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

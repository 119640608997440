import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericState, GenericState } from '../model';
import { addGenericStateListBuilders, addGenericStateSingleBuilders } from '../slice';
import { checkLoginStatus, issueCredential, startWalletLogin } from './CredentialApi';


export interface IssueVc {
    oidcUrl: string;
    sessionId: string;
}


export interface CredentialState extends GenericState<IssueVc> {
}

export const credentialSlice = createSlice({
    name: 'credential',
    initialState: defaultGenericState,
    reducers: {},
    extraReducers: builder => {
        addGenericStateSingleBuilders(builder, issueCredential);
        addGenericStateSingleBuilders(builder, startWalletLogin);
        addGenericStateListBuilders(builder, checkLoginStatus);
    },
});

import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericState, GenericState } from '../model';
import { addGenericStateListBuilders, addGenericStateSingleBuilders } from '../slice';
import { getDemoCredential, getDemoCredentials, saveDemoCredential } from './DemoCredentialApi';


export interface DemoCredential {
    id: string;
    templateId: string;
    credentialJson: string;
    companyWalletUrl: string;
}


export interface DemoCredentialState extends GenericState<DemoCredential> {
}

export const demoCredentialSlice = createSlice({
    name: 'demoCredential',
    initialState: defaultGenericState,
    reducers: {},
    extraReducers: builder => {
        addGenericStateListBuilders(builder, getDemoCredentials);
        addGenericStateSingleBuilders(builder, getDemoCredential);
        addGenericStateSingleBuilders(builder, saveDemoCredential);
    },
});

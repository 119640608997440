import { useKeycloak } from '@react-keycloak/web';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../state/hooks';
// import { companySelector, getCompany, saveCompany } from '../../state/slices';
import { useSelector } from 'react-redux';
import { Button, Card, CardActions, CardContent, CardHeader, Grid, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { DemoCredential, demoCredentialSelector, getDemoCredentials, saveDemoCredential } from '../../state';

export const EditCompany: FC = () => {
    const dispatch = useAppDispatch();
    const {keycloak, initialized} = useKeycloak();
    const {kvkNummer} = useParams();
    const [companyWalletIUrl, setCompanyWalletIUrl] = useState<string | undefined>('')

    const demoCredentials = useSelector(demoCredentialSelector);
    let navigate = useNavigate();

    useEffect(() => {
        dispatch(getDemoCredentials({jwtToken: keycloak.token!}));
    }, [keycloak.token]);

    const demoCredential: DemoCredential = useMemo(() => {
        return demoCredentials.list?.[0];
    }, [demoCredentials.list]);

    useEffect(() => {
        setCompanyWalletIUrl((demoCredential !== undefined) && (demoCredential.companyWalletUrl !== undefined) ? demoCredential.companyWalletUrl : '');
    }, [demoCredential?.companyWalletUrl]);


    function cancel() {
        navigate(-1);
    }

    function save() {
        dispatch(saveDemoCredential({jwtToken: keycloak.token!, id: demoCredential.id, companyWalletUrl: companyWalletIUrl})).then((response) => {
            if (response.type.includes('fulfilled')) {
                setTimeout(() => { // Use timeout the give time to update the redux store.
                    navigate('/')
                }, 250);
            }
        });
    }

    function isValidForm() {
        if (companyWalletIUrl === undefined || companyWalletIUrl === '') {
            return true;
        }
        try {
            new URL(companyWalletIUrl);
            return companyWalletIUrl.startsWith('https://') || companyWalletIUrl.startsWith('http://');
        } catch (_) {
            return false;
        }
    }

    function getName(demoCredential: DemoCredential) {
        if ((demoCredential !== undefined) && (demoCredential.credentialJson !== undefined)) {
            return JSON.parse(demoCredential.credentialJson).naam;
        }
        return "";
    }

    return (
        <div>
            <Grid container spacing={2} flexDirection={'row'}>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardHeader title='Company'/>
                        <CardContent>
                            <Grid container spacing={2} alignItems="center" marginBottom={2}>
                                <Grid item xs={4}>
                                    Naam:
                                </Grid>
                                <Grid item xs={8}>
                                    {getName(demoCredential)}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} alignItems="center" marginBottom={2}>
                                <Grid item xs={4}>
                                    Company wallet url:
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField id="companyWalletUrl" variant="outlined"
                                               value={companyWalletIUrl}
                                               onChange={event => setCompanyWalletIUrl(event.target.value)}
                                               placeholder={"https://wallet.yourwalletprovider.com"}
                                               fullWidth/>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button variant='outlined' onClick={cancel}>Annuleren</Button>
                            <Button variant='contained' disabled={!isValidForm()} onClick={save}>Opslaan</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
        ;
    return (<div/>);
};

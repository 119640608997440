import { useKeycloak } from '@react-keycloak/web';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../state/hooks';
import { useSelector } from 'react-redux';
import { Box, Button, Grid, TextField } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { demoCredentialSelector, getDemoCredential } from '../../state';
import { FillWalletUrl } from '../atoms';

export const SelectWallet: FC = () => {
    const dispatch = useAppDispatch();
    const {keycloak, initialized} = useKeycloak();
    const [searchParams] = useSearchParams();
    const [walletUrl, setWalletUrl] = useState<string>('');
    const templateId = searchParams.get('id');
    let navigate = useNavigate();
    const demoCredential = useSelector(demoCredentialSelector);

    useEffect(() => {
        if (templateId !== null) {
            dispatch(getDemoCredential({jwtToken: keycloak.token!, templateId: templateId}));
        }
    }, [keycloak.token, templateId]);

    useEffect(() => {
        if ((demoCredential.singleItem?.companyWalletUrl !== undefined) && (demoCredential.singleItem?.companyWalletUrl.length > 0)) {
            selectWallet(demoCredential.singleItem?.companyWalletUrl);
        }
        setWalletUrl((demoCredential.singleItem?.companyWalletUrl === undefined) ? '' : demoCredential.singleItem?.companyWalletUrl);
    }, [demoCredential.singleItem?.companyWalletUrl]);

    function selectWallet(walletlUrl: string) {
        navigate('/issue?id=' + templateId + '&callback=' + walletlUrl);
    }

    return (

        <div>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '60px'}}>
                <FillWalletUrl/>
            </Box>
            <Grid container spacing={2} sx={{alignItems: 'center'}}>
                <Grid item xs={8} sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                    <TextField variant='standard' placeholder="Fill in the URL of yout wallet" fullWidth margin="dense" sx={{backgroundColor: '#FAF7F0', marginRight: '40px'}} value={walletUrl} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setWalletUrl(event.target.value);
                    }} ></TextField>
                    <Button variant='contained' onClick={() => selectWallet(walletUrl)} sx={{height: '65px', paddingLeft: '40px', paddingRight: '40px'}}>SELECT</Button>
                </Grid>
            </Grid>

        </div>
    );

};

import { useKeycloak } from '@react-keycloak/web';
import React, { FC, useEffect } from 'react';
import { LinearProgress } from '@mui/material';
import { useAppDispatch } from '../../../state';
import { useLocation } from 'react-router-dom';
import { issueCredential } from '../../../state/slices/credential';

export const IssueVc: FC = () => {
    const dispatch = useAppDispatch();
    const {keycloak} = useKeycloak();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const callbackUrl = searchParams.get('callback');
    const templateId = searchParams.get('id');

    useEffect(() => {
        if (callbackUrl && templateId) {
            dispatch(issueCredential({jwtToken: keycloak.token!, templateId, callbackUrl}))
                .then((data) => {
                        if (data.type.includes('fulfilled')) {
                            window.location.replace(data.payload.oidcUrl);
                        }
                    }
                );
        }
    }, [callbackUrl, templateId]);

    if (callbackUrl && templateId) {
        return (
            <div>
                <LinearProgress/>
            </div>
        );
    } else {
        return (<div>Invalid parameters.</div>);
    }


};

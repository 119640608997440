import { useKeycloak } from '@react-keycloak/web';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../state/hooks';
// import { companySelector, getCompany } from '../../state/slices';
import { useSelector } from 'react-redux';
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Input, Modal, TextField } from '@mui/material';
import { formatDateForDisplay } from '../../state/DateUtils';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import { credentialSelector, issueCredential } from '../../state/slices/credential';

export const ScanQRCode: FC = () => {
    const dispatch = useAppDispatch();
    const { keycloak, initialized } = useKeycloak();
    const [searchParams] = useSearchParams();
    const configurationId = searchParams.get('id');
    let navigate = useNavigate();
    const credentialState = useSelector(credentialSelector);

    useEffect(() => {
        if (configurationId) {
           dispatch(issueCredential({jwtToken: keycloak.token!, templateId: configurationId, callbackUrl: ''}));
        }
    }, [keycloak.token, configurationId]);


    function annuleer() {
        navigate('/');
    }

    return (
        <div>
            <Card variant="outlined">
                <CardHeader title='Uitgeven bevoegdheid uittreksel credential'/>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            Scan onderstaande QR code met je persoonlijke wallet om de geselecteerde bevoegdheid naar je wallet te halen.
                        </Grid>
                        <Grid item xs={12}>
                            { credentialState.singleItem ? <QRCodeCanvas
                                value={credentialState.singleItem!.oidcUrl}
                                size={300}/> : <></>}
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant='outlined' onClick={() => annuleer()}>Annuleren</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};
